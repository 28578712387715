<template>
  <div class="container">
    <div class="content">
      <img src="@/assets/img/live/tile-1.jpg">
      <h2 class="title">
        На Севере — жить! Портал «Наш Север»
      </h2>
      <a href="#" class="link">
        Подробнее
        <arrows-yellow class="arrow" />
      </a>
      <div class="content-text">
        <p class="content-text__text">
          «Наш Север» – это уникальный сервис совместного управления, который позволяет всем жителям Мурманской области, не тратя времени на заполнение бумажных документов и длительный поиск инстанций, эффективно взаимодействовать с властью и реально влиять на развитие родного города и региона.
        </p>
        <p class="content-text__text">
          На портале “Наш север” каждый житель Мурманской области может поучаствовать в развитии и благоустройстве своих населенных пунктов  - подать сообщение о проблеме по 59 категориям, принять участие в голосовании, подать инициативу и посмотреть карту работ в Мурманской области 
        </p>
        <div class="content-text__text content-text__text--qr-code">
          <img class="img-qr-code" src="@/assets/img/live/qr.jpg">
          <p class="content-text__special-text">
            Сканируйте QR-код,
            <br />
            чтобы перейти на портал
            <br />
            и повлиять на развитие
            <br />
            региона
          </p>
        </div>
      </div>

      <swiper-image
        :swiper-class="'tick'"
        :images="images_1"
        :color-bg="'grey'"
      />

      <div class="content-text">
        <h3 class="content-text__title">
          Проекты благоустройства
          <br />
          в Мурманской области 
        </h3>
        <p class="content-text__text">
          Ежегодно в области реализуются проекты
          <br />
          по благоустройству городов 
        </p>
      </div>

      <swiper-image
        :space-between="30"
        :images="images_2"
      />

    </div>
  </div>
</template>

<script>
import ArrowsYellow from "@/components/Icons/ArrowsYellow";
import SwiperImage from "@/components/Parts/SwiperImage";

export default {
  name: "LiveNorth",
  components: {
    ArrowsYellow,
    SwiperImage
  },
  data() {
    return {
      images_1: [
        {
          path: require("@/assets/img/live/swiper1/family.jpg"),
          title: "Сообщения, благоустройство, карта работ, инициативы, голосования"
        },
        {
          path: require("@/assets/img/live/swiper1/family.jpg"),
          title: "Сообщения, благоустройство, карта работ, инициативы, голосования"
        },
      ],
      images_2: [
        {
          path: require("@/assets/img/live/swiper2/tile-1.jpg"),
          title: "Парк Полярный, г. Полярный"
        },
        {
          path: require("@/assets/img/live/swiper2/tile-1.jpg"),
          title: "Парк Полярный, г. Полярный"
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  height: 1172px;
  overflow-x: hidden;
  overflow-y: auto;

  .title {
    padding-top: 93px;
  }

  .link {
    padding-top: 166px;
  }

  .content-text {
    &__text {
      &--qr-code {
        display: flex;
        align-items: center;
      }
      .img-qr-code {
        padding-right: 55px;
      }
    }
  }
}
.swiper-slide {
  text-align: left;
}
</style>